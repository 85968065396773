function initCustomScrollbar( block ) {
	if ( !block ) {return;}
	const $block = $( block );
	const $customScrollbar = $block.find( ".scrollbar-outer:not(.scroll-content)" );

	if ( $customScrollbar.length ) {
		setTimeout( function() {
			if ( $customScrollbar.hasClass( "scroll-content" ) ) {return;}
			{$customScrollbar.scrollbar( {
				ignoreMobile: true,
				ignoreOverlay: true,
				passive: true,
			} );}
		}, 1000 );
	}
}

export default initCustomScrollbar;
