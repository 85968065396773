import debounce from "../utils/debounce.js";
import initCustomScrollbar from "../modules/init-custom-scrollbar.js";

const initBlock = async function( block ) {
	const body = $( "body" );
	const menuToggler = block.find( "[data-menu-toggler]" );
	const menu = block.find( "[data-menu]" );
	await import( "../scrollbar.min.js" );

	class Menu {
		constructor() {
			this.$html = $( "html" );
			this.$body = $( "body" );
			this.$header = $( ".section-header" );
			this.init();
		}

		init() {
			if ( !this.$header.length ) {
				return;
			}

			this.$menu = this.$header.find( ".section-header__nav-list" );
			this.$menu.find( ".dropdown-arrow" ).closest( "li" ).removeClass( "menu-active" );
			this.$menu.find( ".sub-menu" ).slideUp( 0 );

			this.menuToggle();
		}

		menuToggle() {
			const debouncedMenuToggle = debounce( function( $target ) {
				const $parentMenuItem = $target.closest( "li" );
				const $subMenu = $parentMenuItem.find( "> .sub-menu" );
				if ( $parentMenuItem.hasClass( "menu-active" ) ) {
					const $childrenSubmenuParent = $subMenu.find( ".menu-item-has-children" );
					const $childrenSubmenu = $childrenSubmenuParent.find( ".sub-menu" );

					$subMenu.slideUp( 150 );
					$parentMenuItem.removeClass( "menu-active" );

					$childrenSubmenu.slideUp( 150 );
					$childrenSubmenuParent.removeClass( "menu-active" );
				} else {
					$subMenu.slideDown( 150 );
					$parentMenuItem.addClass( "menu-active" );
				}
			}, 150 );
			this.$menu.find( ".dropdown-arrow" ).on( "click", e => {
				e.preventDefault();
				const $this = $( e.currentTarget );
				debouncedMenuToggle( $this );
			} );

			this.$menu.find( ".menu-item-has-children>a[href='#']" ).on( "click", e => {
				e.preventDefault();
				const $this = $( e.currentTarget );
				debouncedMenuToggle( $this );
			} );
		}
	}

	new Menu();

	menuToggler.on( "click", function() {
		if ( menu.hasClass( "active" ) ) {
			hideMenu();
		} else {
			showMenu();
		}
	} );

	function hideMenu() {
		body.removeClass( "scroll-locked" );
		menuToggler.removeClass( "active" );
		menu.removeClass( "active" );
		block.removeClass( "menu-active" );
	}

	function showMenu() {
		body.addClass( "scroll-locked" );
		menuToggler.addClass( "active" );
		menu.addClass( "active" );
		block.addClass( "menu-active" );
	}

	$( window ).on( "scroll", () => {
		if ( $( window ).scrollTop() < 10 ) {
			block.removeClass( "menu-sticky" );
		} else {

			block.addClass( "menu-sticky" );
		}
	} );

	initCustomScrollbar( block );
};

themeUtils.loadBlock( initBlock, "header", ".section-header" );
